@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/mixins';

.title_container {
  width: 100%;
  margin-top: 15vh;
  @include media(m-device) {
    margin-top: 12vh;
  }

  & > h1 {
    text-align: center;
    width: 50%;
    line-height: 1.2;
    font-size: 56px;
    font-weight: bold;
    color: $txt-primary;
    margin-left: 25%;

    @include media(m-device) {
      font-size: 45px;
    }

    @include media(s-device) {
      width: 80%;
      font-size: 32px;
      margin-left: 10%;
    }
  }
}

.search_bar_container {
  width: 40%;
  margin: auto;

  @include media(m-device) {
    width: 60%;
  }

  @include media(s-device) {
    width: 90%;
  }
}


